import { useHistory } from 'react-router';
import { Button } from '../../../shared/formElements/button';
import * as Yup from 'yup';
import {
  Form,
  FormContainer,
  FormSectionContainer,
  ButtonGroupContainer,
  SectionContainer,
  LoadingSpinner,
  Line,
  ImageSplitFormSection,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import {
  activateCardConstants,
  phoneMaxLength,
} from '../../../utils/constants/activate';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContextDispatch, useContextState } from '../../context/context';
import { useState } from 'react';
import { IconSectionCard } from '../../../shared/iconSectionCardSection';
import axios from 'axios';
import { globalConstants } from '../../../utils/constants/global';
import { RequestCardInputs } from './sections/RequestCardInputs';
const {
  activationCode,
  email,
  firstName,
  lastName,
  phoneNumber,
  termsConditionsCheck,
} = activateCardConstants.validations;

export const EmailActivation = () => {
  const dispatch = useContextDispatch();
  const { loading } = useContextState();

  const [serverErrors, setServerErrors] = useState('');

  const { expiredCardMessage } = globalConstants;

  const { push } = useHistory();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      activationCode: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      termsConditionsCheck: false,
    },
  });

  const onSubmit = async (formData: any) => {
    try {
      dispatch('START_LOADING');
      const data = {
        fname: formData.firstName,
        lname: formData.lastName,
        activationCode: formData.activationCode,
        email: formData.email,
        phone: formData.phoneNumber,
      };
      await axios.post('/EmailActivateCard', data);
      dispatch('STOP_LOADING');
      let successLocation = {
        pathname: '/success',
        state: { message: `#### Thank you, you will receive a **digital Mastercard**, sent via SMS to the mobile number you have registered within the next 2 hours.` }
      }

      push(successLocation);
    } catch (err: any) {
      console.log('err', err);
      if (err.response.status === 400) {
        setServerErrors(err.response.data.message);
      } else if (err.response.status === 700) {
        setServerErrors(expiredCardMessage);
      } else {
        setServerErrors('Something went wrong');
      }
      dispatch('STOP_LOADING');
    }
  };

  return (
    <>
      <Line />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <SectionContainer>
            <Markdown children="# Request Digital Card" />
            <Markdown children="Enter your request Details Below:" />
            <FormContainer>
              <FormSectionContainer className="activateCardSection">
                <RequestCardInputs />
                {serverErrors && (
                  <Markdown
                    children={serverErrors}
                    className="validationText"
                  />
                )}
                <ButtonGroupContainer>
                  <Button
                    onClick={() => {
                      methods.handleSubmit(onSubmit);
                    }}
                  >
                    {loading ? <LoadingSpinner show /> : 'Request Card'}
                  </Button>
                </ButtonGroupContainer>
              </FormSectionContainer>
              <FormSectionContainer>
                {process.env.REACT_APP_CARD_OR_ICONS === 'CARD' ? (
                  <ImageSplitFormSection
                    src={process.env.REACT_APP_CARD_FRONT ?? ''}
                  />
                ) : (
                  <IconSectionCard />
                )}
              </FormSectionContainer>
            </FormContainer>
          </SectionContainer>
        </Form>
      </FormProvider>
    </>
  );
};
const validationSchema = Yup.object().shape({
  activationCode: Yup.string()
    .required(activationCode.required)
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
  firstName: Yup.string()
    .required(firstName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: firstName.format,
      excludeEmptyString: true,
    }),
  lastName: Yup.string()
    .required(lastName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: lastName.format,
      excludeEmptyString: true,
    }),
  email: Yup.string().email(email.format).required(email.required),
  phoneNumber: Yup.string()
    .required(phoneNumber.required)
    .matches(/^(0)[0-9]+$/, {
      message: phoneNumber.format,
      excludeEmptyString: true,
    })
    .min(phoneMaxLength, phoneNumber.minLength)
    .max(phoneMaxLength, phoneNumber.maxLength),
  confirmPhoneNumber: Yup.string().oneOf(
    [Yup.ref('phoneNumber'), null],
    "Mobiles numbers don't match"
  ),
  termsConditionsCheck: Yup.bool().oneOf([true], termsConditionsCheck.required),
});
