export const phoneMaxLength = 10;

export const kycValidationConstants = {
    heading: `# KYC Validation`,
    subHeading: `### Please fill out the details below to submit your KYC information`,
    activateCardText: `${process.env.REACT_APP_ACTIVATE_CARD_TEXT}`,
    kycValidationSubText: `To ensure a successful KYC (Know Your Customer) check, please make sure to provide accurate and up-to-date personal details during the verification process.  These details must match the details on your license or passport exactly`,
    phoneNumberSubText: `${process.env.REACT_APP_PHONE_SUB_TEXT}`,
    activateCardButtonText: `Activate Now`,
    termsConditionsCheckValue: 'true',
    termsConditionsText: `I understand and agree to the gift card [Terms & Conditions*](${process.env.REACT_APP_TERMS_LINK})`,
    defaultValue: {
        cardID: '',
        firstName: '',
        middleName: '',
        lastName: '',
        licenseNo: '',
        passportNo: '',
        day: '',
        month: '',
        year: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postcode: '',
        country: 'Australia',
        
    },
    validations: {
        cardID: {
            id: 'cardID',
            required: 'Card ID is required',
            format: 'Card ID is required'
        },
        firstName: {
            id: 'firstName',
            required: 'First given name is required',
            format: 'First given name is required'
        },
        middleName: {
            id: 'middleName',
            required: 'Middle name is required',
            format: 'Middle name must only contain letters'
        },
        lastName: {
            id: 'lastName',
            required: 'Last name is required',
            format: 'Last name is required'
        },
        licenseNo: {
            id: 'licenseNo',
            required: 'License number is required',
            format: 'License number must be a number'
        },
        passportNo: {
            id: 'passportNo',
            required: 'Passport number is required',
            format: 'Passport number is required'
        },
        day: {
            id: 'day',
            required: 'Day is required',
            format: 'Day is required'
        },
        month: {
            id: 'month',
            required: 'Month is required',
            format: 'Month is required'
        },
        year: {
            id: 'year',
            required: 'Year is required',
            format: 'Year is required'
        },
        addressLine1: {
            id: 'addressLine1',
            required: 'Address is required',
            format: 'Address is required'
        },
        city: {
            id: 'city',
            required: 'City is required',
            format: 'City is required'
        },
        state: {
            id: 'state',
            required: 'State is required',
            format: 'State is required'
        },
        postcode: {
            id: 'postcode',
            required: 'Postcode is required',
            format: 'Postcode is required'
        },
        country: {
            id: 'country',
            required: 'Country is required',
            format: 'Country is required'
        },
    }
}