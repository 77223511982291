import styled from 'styled-components/macro';

export const Table = styled.table`
  width: 100%;
`;
export const TableRow = styled.tr``;
export const TableHead = styled.th`
  padding: 5px;
  color: ${({ theme }) => theme.colors.text.subText};
  background-color: ${({ theme }) => theme.colors.primary.default};
`;
export const TableData = styled.td`
  padding: 5px;
  color: ${({ theme }) => theme.colors.text.subText};
`;
export const TermsTable = () => {
  return (
    <Table>
      <TableRow>
        <TableHead>Fee Description</TableHead>
        <TableHead>Fee $ Amount (ex-GST)</TableHead>
      </TableRow>
      <TableRow>
        <TableData>
          Vault Physical Mastercard® Gift Card Terms and ConditionsDisputed
          Transaction Fee - charged where a transaction is disputed in error
          (per transaction)
        </TableData>
        <TableData>$25.00</TableData>
      </TableRow>
    </Table>
  );
};

export const FeesChargesTable = () => {
  return (
    <Table>
      <TableRow>
        <TableHead>Fee Description</TableHead>
        <TableHead></TableHead>
      </TableRow>
      <TableRow>
        <TableHead>Card Issue and Funds Loading</TableHead>
        <TableHead></TableHead>
      </TableRow>
      <TableRow>
        <TableData>Card Issue Fee</TableData>
        <TableData>Nil</TableData>
      </TableRow>
      <TableRow>
        <TableData>Replacement Card Fee for expired cards</TableData>
        <TableData>Nil</TableData>
      </TableRow>
      <TableRow>
        <TableData>Replacement Card Fee for lost or stolen cards</TableData>
        <TableData>$5.95</TableData>
      </TableRow>
      <TableRow>
        <TableHead>ATM Withdrawals</TableHead>
        <TableHead></TableHead>
      </TableRow>
      <TableRow>
        <TableData>ATM Transactions</TableData>
        <TableData>
          As displayed on the ATM screen at the time of the relevant
          transaction.
        </TableData>
      </TableRow>
      <TableRow>
        <TableHead>Account Keeping Fees</TableHead>
        <TableHead></TableHead>
      </TableRow>
      <TableRow>
        <TableData>
          Monthly inactivity fee (charged after 180 days of Card inactivity)
        </TableData>
        <TableData>$5.50</TableData>
      </TableRow>
      <TableRow>
        <TableData>Foreign exchange fee</TableData>
        <TableData>2.99% of the total amount of each transaction</TableData>
      </TableRow>
      <TableRow>
        <TableData>
          Disputed transaction fee (per transaction) This fee may be charged by
          EML in the event EML is unsuccessful in disputing a transaction and
          EML finds that the dispute is lodged fraudulently, or the dispute is
          otherwise found to not be legitimate.
        </TableData>
        <TableData>$27.50</TableData>
      </TableRow>
      <TableRow>
        <TableData>
          Manual funds transfer fee – Card to external bank account transfer
        </TableData>
        <TableData>$27.50</TableData>
      </TableRow>
    </Table>
  );
};

export const TransactionTable = () => {
  return (
    <Table>
      <TableRow>
        <TableHead>Load/transaction</TableHead>
        <TableHead>Limit</TableHead>
      </TableRow>
      <TableRow>
        <TableHead>Point of Sale Limits</TableHead>
        <TableHead></TableHead>
      </TableRow>
      <TableRow>
        <TableData>
          Maximum Point of Sale transaction amount (in any one transaction)
        </TableData>
        <TableData>$4,999</TableData>
      </TableRow>
      <TableRow>
        <TableData>
          Daily Point of Sale limit per day (including ATM Withdrawal
          transactions) (cumulative for all transactions)
        </TableData>
        <TableData>$4,999</TableData>
      </TableRow>
      <TableRow>
        <TableData>
          Maximum number of transactions per day (including POS and ATM
          Transactions)
        </TableData>
        <TableData>25</TableData>
      </TableRow>
      <TableRow>
        <TableHead>ATM Withdrawal Limits (Physical Cards only)</TableHead>
        <TableHead></TableHead>
      </TableRow>
      <TableRow>
        <TableData>Daily ATM Withdrawal Limit</TableData>
        <TableData>$4,999</TableData>
      </TableRow>
      <TableRow>
        <TableData>Maximum ATM Withdrawal Amount per transaction</TableData>
        <TableData>$999</TableData>
      </TableRow>
      <TableRow>
        <TableData>Maximum number of ATM transactions per day</TableData>
        <TableData>10</TableData>
      </TableRow>
      <TableRow>
        <TableHead>Maximum number of ATM transactions per day</TableHead>
        <TableHead>10</TableHead>
      </TableRow>
      <TableRow>
        <TableData>Maximum Card Balance at any one time</TableData>
        <TableData>$4,999</TableData>
      </TableRow>
      <TableRow>
        <TableData>Maximum Load to Card in any one transaction</TableData>
        <TableData>$4,999</TableData>
      </TableRow>
      <TableRow>
        <TableData>Maximum Load to Card per 24 hours</TableData>
        <TableData>$4,999</TableData>
      </TableRow>
      <TableRow>
        <TableData>Daily Total Limit (from ATM and/or POS)</TableData>
        <TableData>$4,999</TableData>
      </TableRow>
      <TableRow>
        <TableData>Maximum number of loads to Card per 24 hours</TableData>
        <TableData>10</TableData>
      </TableRow>
    </Table>
  );
};

export const TermsFeesChargesTable = () => {
  return (
    <Table>
      <TableRow>
        <TableHead>
          Fees and Charges to be paid by the Cardholder (all fees quoted are
          inclusive of GST)
        </TableHead>
        <TableHead></TableHead>
      </TableRow>
      <TableRow>
        <TableHead>Fee Description</TableHead>
        <TableHead>Fee Amount</TableHead>
      </TableRow>
      <TableRow>
        <TableData>
          Monthly inactivity fee (charged after 180 days of Card inactivity)
        </TableData>
        <TableData>$5.50</TableData>
      </TableRow>
      <TableRow>
        <TableData>Replacement Card Fee for lost or stolen cards</TableData>
        <TableData>$5.95</TableData>
      </TableRow>
      <TableRow>
        <TableData>Foreign exchange fee</TableData>
        <TableData>2.99%</TableData>
      </TableRow>
      <TableRow>
        <TableData>Disputed transaction fee (per transaction)</TableData>
        <TableData>$27.50</TableData>
      </TableRow>
      <TableRow>
        <TableData>
          Manual funds transfer fee – Card to external bank account transfer
        </TableData>
        <TableData>$27.50</TableData>
      </TableRow>
    </Table>
  );
};

export const ProductInfoTable = () => {
  return (
    <Table>
      <TableRow>
        <TableData>Product</TableData>
        <TableData>
          Vault Payment Solutions Mastercard A non-cash payment facility in the
          form of a reloadable prepaid Mastercard issued to a customer of the
          distributor to purchase goods and services electronically from
          merchants in Australia and overseas wherever where Mastercard is
          accepted.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Issuer</TableData>
        <TableData>EML Payment Solutions Limited</TableData>
      </TableRow>
      <TableRow>
        <TableData>Distributor</TableData>
        <TableData>Vault Payment Solutions Group Pty Ltd</TableData>
      </TableRow>
      <TableRow>
        <TableData>Start Date</TableData>
        <TableData>12 January 2022</TableData>
      </TableRow>
    </Table>
  );
};

export const ReportingInfoTable = () => {
  return (
    <Table>
      <TableRow>
        <TableData>Complaint Information</TableData>
        <TableData>
          Information about complaints received in relation to the product
          during the reporting period, and if complaints were received, a
          description of the number of complaints and the nature of the
          complaints received and other complaint information set out in
          paragraph RG 271.182 of Regulatory Guide 271 Internal dispute
          resolution.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Distributor Feedback</TableData>
        <TableData>
          Information discovered or held by the distributor that suggests that
          this TMD may no longer be appropriate.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Significant Dealing</TableData>
        <TableData>
          Information about any significant dealing in the product that is not
          consistent with the TMD of which the distributor becomes aware. The
          distributor must provide the information as soon as practicable, or in
          any event, within 10 business days after becoming aware of the
          significant dealing.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Information Requested by EML</TableData>
        <TableData>
          Information reasonably requested by EML. The distributor must provide
          the information as soon as practicable and no later than the date
          specified by EML.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Reporting Period</TableData>
        <TableData>
          The reporting period for this TMD is every 6 months commencing from
          the Start Date.
        </TableData>
      </TableRow>
    </Table>
  );
};

export const ReviewTriggersTable = () => {
  return (
    <Table>
      <TableRow>
        <TableData>Material Complaints</TableData>
        <TableData>
          material complaints (in number or significance) received by EML or the
          distributor in relation to the terms of this product and / or the
          distribution conduct.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Product Performance</TableData>
        <TableData>
          evidence, as determined by EML, of the performance of the product, in
          practice, that may suggest that the product is not appropriate for the
          target market.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Distributor Feedback</TableData>
        <TableData>
          reporting from the distributor, or consistent feedback from the
          distributor on the target market which suggests that this TMD may no
          longer be appropriate.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Substantial Product Change</TableData>
        <TableData>
          a substantial change to the product that is likely to result in this
          TMD no longer being appropriate for the target market.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Significant Dealing</TableData>
        <TableData>
          a material pattern of dealings in the product or of distributor
          conduct that is not consistent with this TMD.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Notification from ASIC</TableData>
        <TableData>
          a notification from ASIC requiring immediate cessation of product
          distribution or particular conduct in relation to the product.
        </TableData>
      </TableRow>
      <TableRow>
        <TableData>Review Period</TableData>
        <TableData>
          the first review, and each ongoing review, must be completed within
          each consecutive 12 month periods from the Start Date.
        </TableData>
      </TableRow>
    </Table>
  );
};
