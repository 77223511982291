import React from 'react';

import { Line, SectionContainer } from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { dynamicLinkFallbackConstants } from '../../../utils/constants/dynamicLinkFallback';
import { useLocation } from 'react-router-dom';

export const DynamicLinkFallback = () => {
  const { subHeading, text } = dynamicLinkFallbackConstants;
  const location: any = useLocation();

  return (
    <>
      <Line />
      <SectionContainer align="center">
        <Markdown children={subHeading} align="center" />
        <Markdown
          children={text}
          align="center"
          className="successText"
        />
      </SectionContainer>
    </>
  );
};
