import React from 'react';
import {
  activateCardConstants,
  phoneMaxLength,
} from '../../../../utils/constants/activate';
import { Markdown } from '../../../../shared/markdown';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxElement } from '../../../../shared/formElements/checkbox';
import Input from '../../../../shared/formElements/input';

export const GetEftposInputs = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { termsConditionsText, defaultValue, phoneNumberSubText } =
    activateCardConstants;

  return (
    <>
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="First Name*"
            fieldName="firstName"
            validation={errors?.firstName?.message}
            width="100%"
          />
        )}
        name="firstName"
        control={control}
        defaultValue={defaultValue.firstName || ''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="Last Name*"
            fieldName="lastName"
            validation={errors?.lastName?.message}
            width="100%"
          />
        )}
        name="lastName"
        control={control}
        defaultValue={defaultValue.lastName || ''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="Unique Card ID*"
            fieldName="eaid"
            validation={errors?.eaid?.message}
            tooltip="How to find my Unique Card ID?"
            tooltipLink="https://vaultps.zendesk.com/hc/en-au/articles/4416855429263-Where-do-I-find-my-card-EAID-"
            width="100%"
          />
        )}
        name="activationCode"
        control={control}
        defaultValue={defaultValue.activationCode || ''}
      />
      <Markdown
        children="This can be located on the top left corner on the back of your card. It is alphanumerical and 9 digits long."
        className="subText"
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="tel"
            label="Mobile Number*"
            fieldName="phoneNumber"
            maxLength={phoneMaxLength}
            validation={errors?.phoneNumber?.message}
            width="100%"
          />
        )}
        name="phoneNumber"
        control={control}
        defaultValue={defaultValue.phoneNumber || ''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="tel"
            label="Confirm Mobile Number*"
            fieldName="confirmPhoneNumber"
            maxLength={phoneMaxLength}
            validation={errors?.confirmPhoneNumber?.message}
            width="100%"
          />
        )}
        name="confirmPhoneNumber"
        control={control}
        defaultValue={defaultValue.phoneNumber || ''}
      />
      {phoneNumberSubText !== 'null' && (
        <Markdown children={phoneNumberSubText} className="subText" />
      )}

      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="Street Address*"
            fieldName="addressLine1"
            validation={errors?.addressLine1?.message}
            width="100%"
          />
        )}
        name="addressLine1"
        control={control}
        defaultValue={''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="City*"
            fieldName="city"
            validation={errors?.city?.message}
            width="100%"
          />
        )}
        name="city"
        control={control}
        defaultValue={''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="tel"
            label="Postcode*"
            fieldName="postcode"
            validation={errors?.postcode?.message}
            width="100%"
          />
        )}
        name="postcode"
        control={control}
        defaultValue={''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="State*"
            fieldName="state"
            validation={errors?.state?.message}
            width="100%"
          />
        )}
        name="state"
        control={control}
        defaultValue={''}
      />

      <Markdown
        children="By clicking 'Exchange Card' you understand and confirm that the details you have entered are correct and cannot be changed."
        className="subText"
      />

      <CheckboxElement
        name="termsConditionsCheck"
        label={termsConditionsText}
        className="termsConditionsText"
        control={control}
        validation={errors?.termsConditionsCheck?.message}
      />
    </>
  );
};
