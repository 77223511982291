import { FC, ReactElement } from 'react';
import gfm from 'remark-gfm';
import reactElementToJSXString from 'react-element-to-jsx-string';
import { MarkdownElement } from './Markdown.styles';
import { Link } from 'react-router-dom';

interface IProps {
  [key: string]: ReactElement;
}

export interface IMarkdownProps {
  children: string;
  className?: string;
  props?: IProps;
  align?: 'left' | 'center' | 'right';
  id?: string;
}

export const Markdown: FC<IMarkdownProps> = ({
  children,
  props,
  className,
  align,
  id
}) => {
  let markdown = children;
  if (props) {
    Object.keys(props).forEach((key) => {
      markdown = markdown.replace(
        `%{${key}}`,
        `${reactElementToJSXString(props[key])}`
      );
    });
  }
  return (
    <MarkdownElement
      className={className}
      align={align}
      allowDangerousHtml={true}
      plugins={[gfm]}
      id={id}
      renderers={{
        link: (props) => {
          if (props.href.includes('mailto') || props.href.includes('http')) {
            return <a href={props.href}>{props.children}</a>;
          } else {
            return <Link to={props.href}>{props.children}</Link>;
          }
        },
      }}
    >
      {children}
    </MarkdownElement>
  );
};
