import { FC, RefObject } from 'react';
import { Transaction } from '../../../context/context';
import {
  BalanceTableContainer,
  Table,
  TableRow,
  TableRowHead,
  TableHead,
  TableData,
  TableBody,
} from './BalanceTableSection.styles';
import { Markdown } from '../../../../shared/markdown';
import { LoadingSpinner, Row } from '../../../../shared/Layout.styles';
import { balanceConstants } from '../../../../utils/constants/balance';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { Button } from '../../../../shared/formElements/button';
import ReCAPTCHA from "react-google-recaptcha";


export interface IBalanceTable {
  transactions: Transaction[] | null;
  balance: number | null;
  hasMore: Boolean;
  getMore: Function;
  loading: boolean;
  searchUntil: Date;
  onChange: any;
  recaptcha: RefObject<ReCAPTCHA>
  serverErrors: any
}

export const BalanceTable: FC<IBalanceTable> = ({ transactions, balance, hasMore, getMore, loading, searchUntil, onChange, recaptcha, serverErrors}) => {
  const { balanceTableHeading } = balanceConstants;

  const handleNumber = (number: number) =>
    number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  // console.log(transactions)

  return (
    <BalanceTableContainer>
      <Row>
        <Markdown children={balanceTableHeading} />

        <Markdown
          children={`## $${balance ? handleNumber(balance) : '0.00'}`}
          className="balanceValue"
        />
      </Row>
      <Row>
      <TableData>
        Showing transactions from today until {format(
                      new Date(searchUntil),
                      "dd-MM-yyyy hh:mmaaaaa'm'"
                    )}
      </TableData>
      </Row>
      <Table>
        <TableBody>
          <TableRowHead>
            <TableHead>Date</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Balance</TableHead>
            <TableHead>Amount</TableHead>
          </TableRowHead>
          {!isEmpty(transactions) ? (
            transactions?.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableData>
                    {format(
                      new Date(item.occurred_at),
                      "dd-MM-yyyy hh:mmaaaaa'm'"
                    )}
                  </TableData>
                  <TableData>
                    {item.reference ? item.reference : null}
                  </TableData>
                  <TableData>
                    {item.running_balance_amount
                      ? `$ ${handleNumber(item.running_balance_amount)}`
                      : null}
                  </TableData>
                  <TableData>{`$ ${handleNumber((item.amount + item.fee_amount))}`}</TableData>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableData>No transactions found in the last 180 days. Click Load More to search earlier than this.</TableData>
            </TableRow>
          )}
        {hasMore 
          ? <TableRow>
            <TableData>
              <span style={{
              display: "flex",
              flexFlow: "column"
              }}>
              <Button onClick={() => getMore()}>
                {loading ? <LoadingSpinner show /> : 'Load More' }
              </Button>
              <p style={{color: "red"}}>{serverErrors}</p>
              </span>
            </TableData>
            <TableData>
              <ReCAPTCHA
                ref={recaptcha}
                sitekey="6LfU8UspAAAAAGYvuYdOkXrHcGGpuEXM4-hxH7Rh"
                onChange={onChange}
              />
            </TableData>
          </TableRow>             
          : ( <TableData>No more transactions.</TableData> )
          }
        </TableBody>
        </Table>
    </BalanceTableContainer>
  );
};
