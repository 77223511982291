import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  kycValidationConstants,
} from '../../../../utils/constants/kyc';
import { Markdown } from '../../../../shared/markdown';
import { Row } from '../../../../shared/Layout.styles';
import { Controller, useFormContext } from 'react-hook-form';
import Input from '../../../../shared/formElements/input';
import ControlledInput from '../../../../shared/formElements/controlledInput';
import AddressInput from '../../../../shared/formElements/addressInput';

function useQuery(){
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const KycValidationInputs = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const {
    kycValidationSubText,
    defaultValue,
  } = kycValidationConstants;

  // Get our URL Query Params
  const query = useQuery();

  // Run only once on load, and set the values for the following fields
  useEffect(() => {
    const cardID = query.get("cardID");
    const firstName = query.get("firstName");
    const lastName = query.get("lastName");
    if (cardID) setValue("cardID", cardID);
    if (firstName) setValue("firstName", firstName);
    if (lastName) setValue("lastName", lastName);
    setValue("country", "Australia");
  });

  return (
    <>
      <Markdown children={kycValidationSubText} className="subText" />
      <Controller
        render={(props) => (
          <ControlledInput
            {...props}
            type="text"
            label="Unique Card ID/EAID*"
            fieldName="cardID"
            validation={errors?.cardID?.message}
            width="100%"
          />
        )}
        name="cardID"
        control={control}
        defaultValue={defaultValue.cardID || ''}
      />
      <Controller
        render={(props) => (
          <ControlledInput
            {...props}
            type="text"
            label="First Name*"
            fieldName="firstName"
            validation={errors?.firstName?.message}
            width="100%"
          />
        )}
        name="firstName"
        control={control}
        defaultValue={defaultValue.firstName || ''}
      />
      <Controller
        render={(props) => (
          <ControlledInput
            {...props}
            type="text"
            label="Middle Name"
            fieldName="middleName"
            validation={errors?.middleName?.message}
            width="100%"
          />
        )}
        name="middleName"
        control={control}
        defaultValue={defaultValue.middleName || ''}
      />
      <Controller
        render={(props) => (
          <ControlledInput
            {...props}
            type="text"
            label="Last Name*"
            fieldName="lastName"
            validation={errors?.lastName?.message}
            width="100%"
          />
        )}
        name="lastName"
        control={control}
        defaultValue={defaultValue.lastName || ''}
      />
      <div style={{width: '100%', height: '15px'}}></div>
      <Markdown children={"Date of Birth"} className="subText" />
      <Row>    
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="tel"
              label="Day*"
              fieldName="day"
              validation={errors?.day?.message}
              maxLength={2}
              width="25%"
            />
          )}
          name="day"
          control={control}
          defaultValue={defaultValue.day || ''}
        />
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="tel"
              label="Month*"
              fieldName="month"
              validation={errors?.month?.message}
              maxLength={2}
              width="25%"
            />
          )}
          name="month"
          control={control}
          defaultValue={defaultValue.month || ''}
        />
        <Controller
          render={(props) => (
            <Input
              {...props}
              type="tel"
              label="Year*"
              fieldName="year"
              validation={errors?.year?.message}
              maxLength={4}
              width="50%"
            />
          )}
          name="year"
          control={control}
          defaultValue={defaultValue.year || ''}
        />
      </Row>
      <div style={{width: '100%', height: '15px'}}></div>
      <Markdown children={"Address"} className="subText" />
      <Controller
          render={(props) => (
            <AddressInput
              {...props}
              label="Address Line 1*"
              fieldName="addressLine1"
              validation={errors?.addressLine1?.message}
              onSelected={(fullAddress: any, addressData: any) => {
                setValue("addressLine1", addressData.address_line_1 || '');
                setValue("addressLine2", addressData.address_line_2 || '');
                setValue("city", addressData.locality_name || '');
                setValue("state", addressData.state_territory || '');
                setValue("postcode", addressData.postcode || '');
                // setValue("country", addressData.country || '');
              }}
            />
          )}
          name="addressLine1"
          control={control}
          defaultValue={defaultValue.addressLine1 || ''}
        />
        <Controller
          render={(props) => (
            <ControlledInput
              {...props}
              type="text"
              label="Address Line 2"
              fieldName="addressLine2"
              validation={errors?.addressLine2?.message}
              width="100%"
            />
          )}
          name="addressLine2"
          control={control}
          defaultValue={defaultValue.addressLine2 || ''}
        />
        <Controller
          render={(props) => (
            <ControlledInput
              {...props}
              disabled 
              type="text"
              label="City*"
              fieldName="city"
              validation={errors?.city?.message}
              width="100%"
            />
          )}
          name="city"
          control={control}
          defaultValue={defaultValue.city || ''}
        />
        <Row>
          <Controller
            render={(props) => (
              <ControlledInput
                {...props}
                disabled
                type="text"
                label="State*"
                fieldName="state"
                validation={errors?.state?.message}
                width="25%"
              />
            )}
            name="state"
            control={control}
            defaultValue={defaultValue.state || ''}
          />
          
          <Controller
            render={(props) => (
              <ControlledInput
                {...props}
                disabled 
                type="text"
                label="Postcode*"
                fieldName="postcode"
                validation={errors?.postcode?.message}
                width="25%"
              />
            )}
            name="postcode"
            control={control}
            defaultValue={defaultValue.postcode || ''}
          />
          <Controller
            render={(props) => (
              <ControlledInput
                {...props}
                disabled
                type="text"
                label="Country*"
                fieldName="country"
                validation={errors?.country?.message}
                width="50%"
              />
            )}
            name="country"
            control={control}
            defaultValue={defaultValue.country || ''}
          />
        </Row>
        <Row>
            <Controller
                render={(props) => (
                    <Input
                        {...props}
                        type="tel"
                        label="License No"
                        fieldName="licenseNo"
                        validation={errors?.licenseNo?.message}
                        width="100%"
                    />
                )}
                name="licenseNo"
                control={control}
                defaultValue={defaultValue.licenseNo || ''}
            />
            </Row>
            <div style={{width: '100%', height: '15px'}}></div>
              <Markdown children={"Passport Information"} className="subText" />
              <Row>
                <Controller
                  render={(props) => (
                      <Input
                          {...props}
                          type="text"
                          label="Passport No"
                          fieldName="passportNo"
                          validation={errors?.passportNo?.message}
                          width="100%"
                      />
                  )}
                  name="passportNo"
                  control={control}
                  defaultValue={defaultValue.passportNo || ''}
                />
                <Controller
                    render={(props) => (
                        <Input
                            {...props}
                            type="text"
                            label="Passport Country of Issue"
                            fieldName="passportCountry"
                            validation={errors?.passportCountry?.message}
                            width="100%"
                        />
                    )}
                    name="passportCountry"
                    control={control}
                    defaultValue={defaultValue.passportNo || ''}
                /> 
              </Row>
            <div style={{width: '100%', height: '15px'}}></div>
            <Markdown children={"Passport Expiry Date"} className="subText" />
            <Row>
                <Controller
                    render={(props) => (
                        <Input
                            {...props}
                            type="tel"
                            label="Expiry Day"
                            fieldName="pp_day"
                            validation={errors?.pp_day?.message}
                            maxLength={2}
                            width="25%"
                        />
                    )}
                    name="pp_day"
                    control={control}
                    defaultValue={defaultValue.day || ''}
                />
                <Controller
                    render={(props) => (
                        <Input
                            {...props}
                            type="tel"
                            label="Expiry Month"
                            fieldName="pp_month"
                            validation={errors?.pp_month?.message}
                            maxLength={2}
                            width="25%"
                        />
                    )}
                    name="pp_month"
                    control={control}
                    defaultValue={defaultValue.month || ''}
                />
                <Controller
                    render={(props) => (
                        <Input
                            {...props}
                            type="tel"
                            label="Expiry Year"
                            fieldName="pp_year"
                            validation={errors?.pp_year?.message}
                            maxLength={4}
                            width="50%"
                        />
                    )}
                    name="pp_year"
                    control={control}
                    defaultValue={defaultValue.year || ''}
                />
              </Row>
    </>
  );
};
