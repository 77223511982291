import React from 'react';
import { SectionContainer } from '../../../shared/Layout.styles';

export const NotFound = () => {
  return (
    <>
      <SectionContainer>Not Found</SectionContainer>
    </>
  );
};
