import React from 'react';
import {
  activateCardConstants,
  phoneMaxLength,
} from '../../../../utils/constants/activate';
import { Markdown } from '../../../../shared/markdown';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxElement } from '../../../../shared/formElements/checkbox';
import Input from '../../../../shared/formElements/input';

export const GetEftposAuthInputs = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { termsConditionsText, defaultValue, phoneNumberSubText } =
    activateCardConstants;

  return (
    <>
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="Authentication Code*"
            fieldName="authCode"
            validation={errors?.authCode?.message}
            width="100%"
          />
        )}
        name="authCode"
        control={control}
        defaultValue={''}
      />
    </>
  );
};
