// import { useHistory } from 'react-router';
import { Button } from '../../../shared/formElements/button';
import * as Yup from 'yup';
import {
  Form,
  FormContainer,
  FormSectionContainer,
  ButtonGroupContainer,
  SectionContainer,
  LoadingSpinner,
  Line,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import {
  kycValidationConstants,
} from '../../../utils/constants/kyc';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContextDispatch, useContextState } from '../../context/context';
import { KycValidationInputs } from './sections/KycValidationInputs';
import { useState } from 'react';
import axios from 'axios';
const {
  cardID,
  firstName,
  middleName,
  lastName,
  licenseNo,
  day,
  month,
  year,
  addressLine1,
  city,
  postcode,
  state,
  country,
} = kycValidationConstants.validations;

export const Kyc = () => {
  const dispatch = useContextDispatch();
  const { loading } = useContextState();

  const [serverErrors, setServerErrors] = useState('');
  const [serverSuccess, setServerSuccess] = useState('');
  

  const {
    heading,
    subHeading,
  } = kycValidationConstants;
  // const { expiredCardMessage } = globalConstants;
  // const { push } = useHistory();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      cardID: '',
      firstName: '',
      middleName: '',
      lastName: '',
      licenseNo: '',
      day: '',
      month: '',
      year: '',
      addressLine1: '',
      city: '',
      postcode: '',
      state: '',
      country: '',
      passport_Number: '',
      passport_expiry: '',
      passport_issuing_country: ''
    },
  });

  const onSubmit = async (formData: any) => {
    try {
      dispatch('START_LOADING');
      setServerSuccess('');
      setServerErrors('');
      const data = {
        card_id: formData.cardID,
        first_name: formData.firstName,
        middle_name: formData.middleName,
        last_name: formData.lastName,
        license_number: formData.licenseNo,
        passport_number: formData.passportNo,
        pp_day: formData.pp_day,
        pp_month: formData.pp_month,
        pp_year: formData.pp_year,
        passport_issuing_country: formData.passportCountry,
        day: formData.day,
        month: formData.month,
        year: formData.year,
        address_line_1: formData.addressLine1,
        address_line_2: formData.addressLine2,
        city: formData.city,
        state: formData.state,
        postcode: formData.postcode,
        // country: formData.country
        country: "AU"
      };
      await axios.post(`${process.env.REACT_APP_VAULT_API}/kyc/update`, data);
      setServerSuccess('KYC Information successfully submitted!');
      dispatch('STOP_LOADING');
    } catch (err: any) {
      if (err && err?.response?.status === 400 && err?.response?.data?.message) {
        setServerErrors(err?.response?.data?.message);
      } else if (err && err.message) {
        setServerErrors(err.message);
      } else {
        setServerErrors("Unknown Error");
      }
      dispatch('STOP_LOADING');
    }
  };

  return (
    <>
        <Line />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <SectionContainer>
              <Markdown children={heading} />
              <Markdown children={subHeading} />
              
              <FormContainer>
                <FormSectionContainer className="kycValidateSection">
                 {serverSuccess ? (
                    <Markdown
                      children={serverSuccess}
                      className="successText"
                    />
                  ) : <KycValidationInputs />}
                  {serverErrors && (
                    <Markdown
                      children={serverErrors}
                      className="validationText"
                    />
                  )}
                  
                  {serverSuccess ? null : 
                    <ButtonGroupContainer>
                      <Button
                        onClick={() => {
                          methods.handleSubmit(onSubmit);
                        }} >
                        {loading ? (
                          <LoadingSpinner show />
                        ) : (
                          "Submit KYC"
                        )}
                      </Button>
                    </ButtonGroupContainer>
                  }
                </FormSectionContainer>
              </FormContainer>
            </SectionContainer>
          </Form>
        </FormProvider>
    </>
  );
};
const validationSchema = Yup.object().shape({
    cardID: Yup.string()
    .required(cardID.required)
    .matches(/[\dA-Za-z]+/, {
      message: cardID.format,
      excludeEmptyString: true,
    }),
    firstName: Yup.string()
    .required(firstName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: firstName.format,
      excludeEmptyString: true,
    }),
    middleName: Yup.string()
    //.required(middleName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: middleName.format,
      excludeEmptyString: true,
    }),
    lastName: Yup.string()
    .required(lastName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: lastName.format,
      excludeEmptyString: true,
    }),
    licenseNo: Yup.string()
    .matches(/[0-9]+/, {
      message: licenseNo.format,
      excludeEmptyString: true,
    }),
    pp_day: Yup.string().notRequired()
    .max(2, "Please enter a valid day.")
    .test("pp_day", "Please enter a valid day.", (value) => !value || (parseInt(value!) <= 31 && parseInt(value!) > 0))
    .test({
      name: "dayOfFeb",
      message: "Please enter a valid day in February.",
      test: (value, ctx) => {
        if (!value) return true;
        const month = ctx.parent.month != null ? parseInt(ctx.parent.month) : undefined;
        const year = ctx.parent.year != null ? parseInt(ctx.parent.year) : undefined;
        // Handle the leap year!
        if (year && (year % 4) === 0) {
          if (month === 2) {
            return parseInt(value!) <= 29;  
          }
        }
        if (month === 2) {
          return parseInt(value!) <= 28;
        }
        return true;
      }
    })
    .test({
      name: "dayOf30",
      message: "Please enter a valid day of this month.",
      test: (value, ctx) => {
        if (!value) return true;
        const month = ctx.parent.month != null ? parseInt(ctx.parent.month) : undefined;
        if (month === 4 || 
          month === 6 || 
          month === 9 || 
          month === 11) {
          return parseInt(value!) <= 30;
        }
        return true;
      }
    })
    .matches(/[0-9]+/, {
      message: day.format,
      excludeEmptyString: true,
    })
    ,
    pp_month: Yup.string().notRequired()
    .max(2, "Please enter a valid month.")
    .test("pp_month", "Please enter a valid month.", (value) =>  !value || (parseInt(value!) <= 12 && parseInt(value!) > 0))
    .matches(/[0-9]+/, {
      message: month.format,
      excludeEmptyString: true,
    }),
    pp_year: Yup.string().notRequired()
    .max(4, "Please enter a valid year.")
    .test("pp_year", "Please enter a valid year.", (value) => !value || (parseInt(value!) >= 1800 && parseInt(value!) >= (new Date().getFullYear())))
    .matches(/[0-9]+/, {
      message: year.format,
      excludeEmptyString: true,
    }),
    day: Yup.string()
    .required(day.required)
    .max(2, "Please enter a valid day.")
    .test("day", "Please enter a valid day.", (value) => parseInt(value!) <= 31 && parseInt(value!) > 0)
    .test({
      name: "dayOfFeb",
      message: "Please enter a valid day in February.",
      test: (value, ctx) => {
        const month = ctx.parent.month != null ? parseInt(ctx.parent.month) : undefined;
        const year = ctx.parent.year != null ? parseInt(ctx.parent.year) : undefined;
        // Handle the leap year!
        if (year && (year % 4) === 0) {
          if (month === 2) {
            return parseInt(value!) <= 29;  
          }
        }
        if (month === 2) {
          return parseInt(value!) <= 28;
        }
        return true;
      }
    })
    .test({
      name: "dayOf30",
      message: "Please enter a valid day.",
      test: (value, ctx) => {
        const month = ctx.parent.month != null ? parseInt(ctx.parent.month) : undefined;
        if (month === 4 || 
          month === 6 || 
          month === 9 || 
          month === 11) {
          return parseInt(value!) <= 30;
        }
        return true;
      }
    })
    .matches(/[0-9]+/, {
      message: day.format,
      excludeEmptyString: true,
    }),
    month: Yup.string()
    .required(month.required)
    .max(2, "Please enter a valid month.")
    .test("month", "Please enter a valid month.", (value) => parseInt(value!) <= 12 && parseInt(value!) > 0)
    .matches(/[0-9]+/, {
      message: month.format,
      excludeEmptyString: true,
    }),
    year: Yup.string()
    .required(year.required)
    .max(4, "Please enter a valid year.")
    .test("year", "Please enter a valid year.", (value) => parseInt(value!) >= 1800 && parseInt(value!) < (new Date().getFullYear() + 1))
    .matches(/[0-9]+/, {
      message: year.format,
      excludeEmptyString: true,
    }),
    addressLine1: Yup.string()
    .required(addressLine1.required)
    .matches(/^([A-Za-z0-9\s\-'".])*$/, {
      message: addressLine1.format,
      excludeEmptyString: true,
    }),
    city: Yup.string()
    .required(city.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: city.format,
      excludeEmptyString: true,
    }),
    postcode: Yup.string()
    .required(postcode.required)
    .matches(/^([A-Za-z0-9\s\-'".])*$/, {
      message: postcode.format,
      excludeEmptyString: true,
    }),
    state: Yup.string()
    .required(state.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: state.format,
      excludeEmptyString: true,
    }),
    country: Yup.string()
    .required(country.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: country.format,
      excludeEmptyString: true,
    }),
});
