import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Markdown } from '../../markdown';
import { ControlledInputElement, ControlledInputContainer } from './ControlledInput.styles';

export interface IControlledInputProps {
  name?: string;
  label?: string;
  type?: string;
  validation?: string;
  value?: string;
  width?: '25%' | '50%' | '75%' | '100%';
  maxLength?: number;
  disabled?: boolean
  fieldName: string;
  field: any;
}

const ControlledInput = forwardRef<HTMLInputElement, IControlledInputProps>(
  ({ type, label, validation, width, fieldName, maxLength, disabled, field }, ref) => {
    const { setValue } = useFormContext();
    return (
      <ControlledInputContainer width={width}>
        <ControlledInputElement
          label={label}
          value={field.value}
          variant="outlined"
          inputProps={{ maxLength: maxLength }}
          type={type}
          error={!!validation}
          disabled={disabled || false}
          inputRef={ref}
          onChange={(e) => {
            setValue(fieldName, e.target.value);
          }}
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key) && type === 'tel') {
              e.preventDefault();
            }
          }}
        />

        {validation && (
          <Markdown children={validation} className="validationText" />
        )}
      </ControlledInputContainer>
    );
  }
);
export default ControlledInput;
