import {
  SectionContainer,
  ButtonGroupContainer,
  Line,
  Image,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { landingConstants } from '../../../utils/constants/landing';
import { Button } from '../../../shared/formElements/button';
import { useHistory } from 'react-router';
import { IconSection } from '../../../shared/iconSectionHome';

export const Landing = () => {
  const {
    subHeading,
    activateCardButtonText,
    balanceButtonText
  } = landingConstants;

  const { push } = useHistory();

  return (
    <>
      <Line />
      <SectionContainer align="center">
        <Markdown children={subHeading} align="center" />
        {process.env.REACT_APP_CARD_OR_ICONS === 'CARD' ? (
          <Image src={process.env.REACT_APP_CARD_FRONT ?? ''} />
        ) : (
          <IconSection />
        )}

        <ButtonGroupContainer align="center">
          <Button onClick={() => push('/activate')} className="centerLeftBtn">
            {activateCardButtonText}
          </Button>
          <Button
            onClick={() => push('/balance')}
            children={balanceButtonText}
            className="centerRightBtn"
          />
        </ButtonGroupContainer>
      </SectionContainer>
    </>
  );
};
