export const balanceConstants = {
  heading: `# Check your card balance`,
  subHeading: `#### Please fill in your **Unique Card ID** below to check your card balance.`,
  balanceText: `This can be located on the top left corner on the back of your card. It is alphanumerical and 9 digits long.`,
  balanceButtonText: `Check Balance`,
  balanceTableHeading: `## Balance`,
  defaultValue: {
    activationCode: '',
  },
};
