import React from 'react';
import {
    activateCardConstants,
    phoneMaxLength,
} from '../../../../utils/constants/activate';
import {Markdown} from '../../../../shared/markdown';
import {Controller, useFormContext} from 'react-hook-form';
import {CheckboxElement} from '../../../../shared/formElements/checkbox';
import Input from '../../../../shared/formElements/input';
import {useLocation} from "react-router-dom";

function useQuery(){
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const RequestCardInputs = () => {
    const {
        control,
        formState: {errors},
        setValue,
    } = useFormContext();
    const {termsConditionsText, defaultValue, phoneNumberSubText} =
        activateCardConstants;

    const query = useQuery();

    let email = query.get('email')
    let name = query.get('name')
    let lName = query.get('last_name')
    let activationCode = query.get('code')

    if (email) setValue("email", email)
    if (name) setValue("firstName", name)
    if (lName) setValue("lastName", lName)
    if (activationCode) setValue("activationCode", activationCode)


    return (
        <>
            <Controller
                render={(props) => (
                    <Input
                        {...props}
                        type="email"
                        label="Email Address*"
                        fieldName="email"
                        validation={errors?.email?.message}
                        width="100%"
                        defaultValue={email ?? undefined}
                        disabled={email !== null}
                    />
                )}
                name="email"
                control={control}
                defaultValue={'Jeff'}
            />
            <Controller
                render={(props) => (
                    <Input
                        {...props}
                        type="text"
                        label="First Name*"
                        fieldName="firstName"
                        validation={errors?.firstName?.message}
                        width="100%"
                        defaultValue={name ?? undefined}
                    />
                )}
                name="firstName"
                control={control}
                defaultValue={defaultValue.firstName || ''}
            />
            <Controller
                render={(props) => (
                    <Input
                        {...props}
                        type="text"
                        label="Last Name*"
                        fieldName="lastName"
                        validation={errors?.lastName?.message}
                        width="100%"
                        defaultValue={lName ?? undefined}
                    />
                )}
                name="lastName"
                control={control}
                defaultValue={defaultValue.lastName || ''}
            />
            <Controller
                render={(props) => (
                    <Input
                        {...props}
                        type="tel"
                        label="Mobile Number*"
                        fieldName="phoneNumber"
                        maxLength={phoneMaxLength}
                        validation={errors?.phoneNumber?.message}
                        width="100%"
                    />
                )}
                name="phoneNumber"
                control={control}
                defaultValue={defaultValue.phoneNumber || ''}
            />
            <Controller
                render={(props) => (
                    <Input
                        {...props}
                        type="tel"
                        label="Confirm Mobile Number*"
                        fieldName="confirmPhoneNumber"
                        maxLength={phoneMaxLength}
                        validation={errors?.confirmPhoneNumber?.message}
                        width="100%"
                    />
                )}
                name="confirmPhoneNumber"
                control={control}
                defaultValue={defaultValue.phoneNumber || ''}
            />
            {phoneNumberSubText !== 'null' && (
                <Markdown children={phoneNumberSubText} className="subText"/>
            )}
            <Controller
                render={(props) => (
                    <Input
                        {...props}
                        type="text"
                        label="Activation Code*"
                        fieldName="activationCode"
                        validation={errors?.activationCode?.message}
                        width="100%"
                        defaultValue={activationCode ?? undefined}
                    />
                )}
                name="activationCode"
                control={control}
                defaultValue={defaultValue.activationCode || ''}
            />
            <Markdown
                children="By clicking 'Request Card' you understand and confirm that the details you have entered are correct and cannot be changed."
                className="subText"
            />

            <CheckboxElement
                name="termsConditionsCheck"
                label={termsConditionsText}
                className="termsConditionsText"
                control={control}
                validation={errors?.termsConditionsCheck?.message}
            />
        </>
    );
};
