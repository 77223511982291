import React from 'react';

import { Line, SectionContainer } from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { successConstants } from '../../../utils/constants/success';
import { useLocation } from 'react-router-dom';

export const Success = () => {
  const { subHeading, successText } = successConstants;
  const location: any = useLocation();

  return (
    <>
      <Line />
      <SectionContainer align="center">
        <Markdown children={subHeading} align="center" />
        <Markdown
          children={
            location?.state?.message ? location?.state?.message : successText
          }
          align="center"
          className="successText"
        />
      </SectionContainer>
    </>
  );
};
