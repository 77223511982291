import { Component } from 'react';
import styled from 'styled-components/macro';

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
  errorInfo: any;
}
const ErrorBoundaryContainer = styled.div`
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.white};
`;

const ReturnLink = styled.a`
  background-color: ${({ theme }) => theme.colors.primary.default};
  padding: 10px;
  list-style: none;
  text-decoration: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.text.white};
`;

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  // @ts-ignore
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContainer>
          <h2>Something went wrong.</h2>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            <br />
            <ReturnLink href="/">Click here to return </ReturnLink>
          </div>
        </ErrorBoundaryContainer>
      );
    }

    return this.props.children;
  }
}
// @ts-ignore
export default ErrorBoundary;
