import { useHistory } from 'react-router';
import { Button } from '../../../shared/formElements/button';
import * as Yup from 'yup';
import {
  Form,
  FormContainer,
  FormSectionContainer,
  ButtonGroupContainer,
  SectionContainer,
  LoadingSpinner,
  Line,
  ImageSplitFormSection,
} from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import {
  activateCardConstants,
  phoneMaxLength,
} from '../../../utils/constants/activate';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContextDispatch, useContextState } from '../../context/context';
import { useState } from 'react';
import { IconSectionCard } from '../../../shared/iconSectionCardSection';
import axios from 'axios';
import { globalConstants } from '../../../utils/constants/global';
import { GetEftposInputs } from './sections/GetEftposInputs';
import { GetEftposAuthInputs } from './sections/GetEftposAuthInputs';
const {
  activationCode,
  firstName,
  lastName,
  phoneNumber,
  termsConditionsCheck,
  authCode
} = activateCardConstants.validations;

const FORM_STATE = {
  DETAILS: "DETAILS",
  TWO_FA_AUTH: "TWO_FA_AUTH"
}

export const GetEftpos = () => {
  const dispatch = useContextDispatch();
  const { loading } = useContextState();

  const [serverErrors, setServerErrors] = useState('');
  const [savedForm, setSavedForm] = useState({});
  const [currentForm, setCurrentForm] = useState(FORM_STATE.DETAILS)

  const { expiredCardMessage } = globalConstants;

  const { push } = useHistory();

  const detailFormMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      firstName: '',
      lastName: '',
      eaid: '',
      phoneNumber: '',
      addressLine1: '',
      city: '',
      postcode: '',
      state: '',
      termsConditionsCheck: false,
      authCode: null
    },
  });

  const onSubmitDetailForm = async (formData: any) => {
    try {
      dispatch('START_LOADING');
      const data = {
        addressLine1: formData.addressLine1,
        city: formData.city,
        eAID: formData.eaid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phoneNumber,
        postcode: formData.postcode,
        state: formData.state,
      };
      await axios.post('/ExchangeCard', data);
      setSavedForm(data);
      setCurrentForm(FORM_STATE.TWO_FA_AUTH);
      dispatch('STOP_LOADING');
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setServerErrors(err?.response?.data?.message);
      } else if (err?.response?.status === 700) {
        setServerErrors(expiredCardMessage);
      } else {
        setServerErrors('Something went wrong');
      }
      dispatch('STOP_LOADING');
    }
  };

  const onSubmitAuthForm = async (formData: any) => {
    try {
      dispatch('START_LOADING');
      const data = {
        ...savedForm,
        authCode: formData.authCode
      };
      await axios.post('/ExchangeCard', data);
      dispatch('STOP_LOADING');
      push({
        pathname: '/success',
        state: {
          message:
            'Thank you, we have successfully received your exchange request. Your physical card will be sent in the mail and you should receive it **within 20 business** days. To check the status of your exchange at any time contact info@vaultps.com.au.',
        },
      });
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setServerErrors(err?.response?.data?.message);
      } else if (err?.response?.status === 700) {
        setServerErrors(expiredCardMessage);
      } else {
        setServerErrors('Something went wrong');
      }
      dispatch('STOP_LOADING');
    }
  };

  const _renderDetailForm = () => {
    return (
      <FormProvider {...detailFormMethods}>
      <Form onSubmit={detailFormMethods.handleSubmit(onSubmitDetailForm)}>
        <SectionContainer>
          <Markdown children="# Exchange for Eftpos" />
          <Markdown children="Enter your request Details Below:" />
          <FormContainer>
            <FormSectionContainer className="activateCardSection">
              <GetEftposInputs />
              {serverErrors && (
                <Markdown
                  children={serverErrors}
                  className="validationText"
                />
              )}
              <ButtonGroupContainer>
                <Button
                  onClick={() => {
                    detailFormMethods.handleSubmit(onSubmitDetailForm);
                  }}
                >
                  {loading ? <LoadingSpinner show /> : 'Exchange Card'}
                </Button>
              </ButtonGroupContainer>
            </FormSectionContainer>
            <FormSectionContainer>
              {process.env.REACT_APP_CARD_OR_ICONS === 'CARD' ? (
                <ImageSplitFormSection
                  src={process.env.REACT_APP_CARD_FRONT ?? ''}
                />
              ) : (
                <IconSectionCard />
              )}
            </FormSectionContainer>
          </FormContainer>
        </SectionContainer>
      </Form>
    </FormProvider>
    );
  }

  const _renderAuthForm = () => {
    return (
      <FormProvider {...detailFormMethods}>
      <Form onSubmit={detailFormMethods.handleSubmit(onSubmitAuthForm)}>
        <SectionContainer>
          <Markdown children="# Exchange for Eftpos" />
          <Markdown children="To confirm your ownership of the eftpos card, we have send you a two factor verification code." />
          <Markdown children="Please enter your code below:" />
          <FormContainer>
            <FormSectionContainer className="activateCardSection">
              <GetEftposAuthInputs />
              {serverErrors && (
                <Markdown
                  children={serverErrors}
                  className="validationText"
                />
              )}
              <ButtonGroupContainer>
                <Button
                  onClick={() => {
                    detailFormMethods.handleSubmit(onSubmitAuthForm);
                  }}
                >
                  {loading ? <LoadingSpinner show /> : 'Submit'}
                </Button>
              </ButtonGroupContainer>
            </FormSectionContainer>
            <FormSectionContainer>
              {process.env.REACT_APP_CARD_OR_ICONS === 'CARD' ? (
                <ImageSplitFormSection
                  src={process.env.REACT_APP_CARD_FRONT ?? ''}
                />
              ) : (
                <IconSectionCard />
              )}
            </FormSectionContainer>
          </FormContainer>
        </SectionContainer>
      </Form>
    </FormProvider>
    );
  }

  const _renderForm = () => {
    switch (currentForm) {
      case FORM_STATE.DETAILS:
        return _renderDetailForm();
      case FORM_STATE.TWO_FA_AUTH:
        return _renderAuthForm();
      default: 
      return (
        <div>Error no form state</div>
      );
    }
  }

  return (
    <>
      <Line />
      {_renderForm()}
    </>
  );
};
const validationSchema = Yup.object().shape({
  eaid: Yup.string()
    .required(activationCode.required)
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
  firstName: Yup.string()
    .required(firstName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: firstName.format,
      excludeEmptyString: true,
    }),
  lastName: Yup.string()
    .required(lastName.required)
    .matches(/^([A-Za-z\s\-'".])*$/, {
      message: lastName.format,
      excludeEmptyString: true,
    }),
  phoneNumber: Yup.string()
    .required(phoneNumber.required)
    .matches(/^(0)[0-9]+$/, {
      message: phoneNumber.format,
      excludeEmptyString: true,
    })
    .min(phoneMaxLength, phoneNumber.minLength)
    .max(phoneMaxLength, phoneNumber.maxLength),
  confirmPhoneNumber: Yup.string().oneOf(
    [Yup.ref('phoneNumber'), null],
    "Mobiles numbers don't match"
  ),

  addressLine1: Yup.string().required('Street address is required'),
  city: Yup.string()
    .required('City is required')
    .matches(/^[A-Za-z ]*$/, {
      message: 'City is invalid',
      excludeEmptyString: true,
    }),
  postcode: Yup.string()
    .required('Postcode is required')
    .matches(/^[0-9]*$/, {
      message: 'Postcode is invalid',
      excludeEmptyString: true,
    }),
  state: Yup.string()
    .required('State is required')
    .matches(/^[A-Za-z]*$/, {
      message: 'State is invalid',
      excludeEmptyString: true,
    }),
  termsConditionsCheck: Yup.bool().oneOf([true], termsConditionsCheck.required),
});

const authCodeValidationSchema = Yup.object().shape({
  eaid: Yup.string()
    .required(authCode.required)
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: authCode.format,
      excludeEmptyString: true,
    })
});
