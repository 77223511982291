import styled from 'styled-components/macro';
import TextField from '@material-ui/core/TextField';
import {
  grey,
  fontFamily,
  mainFontFamily,
  inputBorder,
  inputBorderOnFocusColor,
  inputBorderOnHoverColor,
  inputTextColor,
} from '../../../styles/theme';

export interface IInputContainerProps {
  width?: '25%' | '50%' | '75%' | '100%';
}
export const AddressInputContainer = styled.div<IInputContainerProps>`
  position: relative;
  padding: 10px 0;
  width: ${({ width }) =>
    width === '25%'
      ? '25%'
      : width === '50%'
      ? '50%'
      : width === '75%'
      ? '75%'
      : width === '100%'
      ? '100%'
      : 'inherit'};
`;

export const AddressInputElement = styled(TextField)`
  width: 100%;
  padding: 10px;

  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.primary.default};
    font-family: ${mainFontFamily}, ${fontFamily};
  }

  .MuiInputBase-input {
    font-size: 14px;
    color: black;
  }
  .MuiOutlinedInput-input {
    padding: 14px;
    color: ${inputTextColor};
  }
  .MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(15px, 15px);
    font-family: ${mainFontFamily}, ${fontFamily};
    color: ${grey};
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border-color: ${inputBorder};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${inputBorderOnFocusColor};
  }
  .MuiFormLabel-root.Mui-error {
    color: ${grey};
  }

  &:hover {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${inputBorderOnHoverColor};
    }
  }
  .MuiFormLabel-root.MuiOutlinedInput-input {
    color: ${inputBorderOnHoverColor};
  }
`;

export const AddressInputDropdownContainer = styled.div`

  ul.af_list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: solid 1px #666;
    background: !white;
  }
  li.af_item {
    background-color: white;
    cursor: pointer;
  }
  li.af_hover {
    background-color: steelblue;
    color: white;
  }
  li.af_footer {
    background-color: white;
    font-size: 0.8em;
    color: #666;
    text-align: right;
  }

  span.af_hl {
    background-color: white;
  }

  .address-autocomplete {
    background: white;
    position: relative !important;
    width: 100%;
  }

  .address-autocomplete__suggestions {
    padding: 0;
    background: white;
    border-radius: 3px;
    z-index: 1001;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
    list-style: none;
    width: 100%;
  }

  .address-autocomplete__suggestions__item {
    font-size: 1rem;
    text-align: left;
    padding: 10px;
    background: white;
    cursor: pointer;
    text-overflow: ellipsis;
  }

  .address-autocomplete__suggestions__item--active {
    background: #eef0f2;
    font-weight: bold;
  }
`;
