import React, {
  useEffect,
  useRef,
  useState,
  forwardRef
} from 'react';
import { useFormContext } from 'react-hook-form';
import { Markdown } from '../../markdown';
import { AddressInputElement, AddressInputContainer, AddressInputDropdownContainer } from './AddressInput.styles';

export interface IAddressInputProps {
  name?: string;
  label?: string;
  type?: string;
  validation?: string;
  width?: '25%' | '50%' | '75%' | '100%';
  maxLength?: number;
  fieldName: string;
  onSelected: Function;
  field: any;
}

const fieldID = "address-finder-field";

const AddressInput = forwardRef<HTMLInputElement, IAddressInputProps>(
  ({ type, label, validation, width, fieldName, maxLength, onSelected, field }, ref) => {
    const { setValue } = useFormContext();

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const mounted = useRef(false);

    useEffect(() => {
      mounted.current = true;
      if (window.AddressFinder) {
        setScriptLoaded(true);
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
      script.async = true;
      script.addEventListener('load', () => {
        if (mounted.current) {
          setScriptLoaded(true);
        }
      });
      window.document.body.appendChild(script);
      return () => {
        mounted.current = false;
        window.document.body.removeChild(script);
      };
    }, []);

      useEffect(() => {
        if (scriptLoaded && mounted.current) {
          const widget = new window.AddressFinder.Widget(
            document.getElementById(fieldID) as HTMLElement,
            process.env.REACT_APP_ADDRESS_FINDER_KEY!,
            'AU',
            {
              list_class: 'address-autocomplete__suggestions',
              item_class: 'address-autocomplete__suggestions__item',
              hover_class: 'address-autocomplete__suggestions__item--active',
              manual_style: false,
              container: document.getElementById(`container-${fieldID}`),
              address_params: {},
              max_results: 5,
            },
          );
    
          widget.on('address:select', (fullAddress, metaData) => {
            let addressData: any = { ...metaData, country: "AU" };
    
            onSelected(fullAddress, addressData);
          });
        }
        // eslint-disable-next-line
      }, [scriptLoaded]);

    return (
      <AddressInputContainer width={width}>
        <AddressInputElement
          id={fieldID}
          label={label}
          variant="outlined"
          value={field.value}
          inputProps={{ maxLength: maxLength }}
          type={type}
          error={!!validation}
          inputRef={ref}
          onChange={(e) => {
            setValue(fieldName, e.target.value);
          }}
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key) && type === 'tel') {
              e.preventDefault();
            }
          }} />
          {validation && (
            <Markdown children={validation} className="validationText" />
          )}
          <AddressInputDropdownContainer
            id={`container-${fieldID}`} 
            className="address-autocomplete"
          />
      </AddressInputContainer>
    );
  }
);
export default AddressInput;
